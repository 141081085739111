import {ChevronRightIcon, QuestionMarkCircleIcon} from "@heroicons/react/solid";
import {ethers} from "ethers";
import {useEffect, useState} from "react";

import {useWeb3} from "../../contexts/web3.context";
import {chainLogos, isTestnet} from "../Parts/ChainLogos";

export default function SelectAddress({ inputAddress, setInputAddress, setOnchainidAddress, setStep }) {
  const web3 = useWeb3();

  const [addressError, setAddressError] = useState('');
  const [storedIdentities, setStoredIdentities] = useState([]);

  useEffect(() => {
    if (window.localStorage) {
      const identities = JSON.parse(window.localStorage.getItem('identities') ?? '[]');

      setStoredIdentities(identities);
    }
  }, []);

  async function validate(event) {
    event?.preventDefault();

    return loadAddress({ address: inputAddress });
  }

  async function selectStoredIdentity({ address, chainId }) {
    setInputAddress(address);

    return loadAddress({ address, chainId });
  }

  async function loadAddress({ address, chainId }) {
    setAddressError(null);

    if (chainId) {
      const provider = await web3.getProvider();
      const currentNetwork = await provider.getNetwork();

      if (currentNetwork.chainId !== chainId) {
        setAddressError({
          code: 'INVALID_NETWORK',
        });
        return;
      }
    }

    if (address.includes('.')) {
      try {
        const provider = await web3.getProvider();
        const resolvedAddress = await provider.resolveName(address);

        if (!resolvedAddress) {
          setAddressError({
            code: 'ENS_RESOLVED_NULL',
          });
          return;
        }

        setOnchainidAddress(resolvedAddress)
        setStep('LOAD_ONCHAINID');
        return;
      } catch (error) {
        if (error.code === 'UNSUPPORTED_OPERATION') {
          setAddressError({
            code: 'ENS_NOT_SUPPORTED',
          });
        } else {
          setAddressError({
            code: 'NO_PROVIDER',
          });
        }
      }
    } else if (address.length === 42) {
      try {
        const validatedAddress = ethers.utils.getAddress(address);

        setOnchainidAddress(validatedAddress);
        setStep('LOAD_ONCHAINID');
        return;
      } catch (error) {
        setAddressError({
          code: 'INVALID_ADDRESS',
        });
      }
    } else {
      setAddressError({
        code: 'INVALID_ADDRESS',
      });
    }
  }

  return (
    <article className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <h1>ONCHAINID Identity Provider</h1>
      <form onSubmit={validate}>
        <label htmlFor="oid-address" className="block text-sm font-medium text-gray-700">Address or ENS (DID format did:oid: is supported)</label>
        <div className="mt-1">
          <input
            type="text"
            id="oid-address"
            name="oid-address"
            value={inputAddress}
            onChange={(e) => setInputAddress(e.target.value)}
            autoComplete="off"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {addressError?.code === 'INVALID_ADDRESS' && (
          <p className="text-red-700">This address doesn't look right.</p>
        )}
        {addressError?.code === 'NO_PROVIDER' && (
          <p className="text-red-700">Resolving a name requires to be connected to the blockchain.</p>
        )}
        {addressError?.code === 'ENS_NOT_SUPPORTED' && (
          <p className="text-red-700">The selected network doesn't support name resolution.</p>
        )}
        {addressError?.code === 'ENS_RESOLVED_NULL' && (
          <p className="text-red-700">That name has resolved to a null address.</p>
        )}
        {addressError?.code === 'INVALID_NETWORK' && (
          <p className="text-red-700">The selected address last authenticated on a different network (check Metamask or press "next" to use current network).</p>
        )}

        <div className="mt-2 flex justify-end">
          <button
            type="submit"
            className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Next
          </button>
        </div>
      </form>

      <ul className="mt-2">
        {storedIdentities.map((identity) => (
          <li key={identity.address}>
            <button className="flex items-center justify-between hover:bg-gray-50 w-full" onClick={() => selectStoredIdentity({ address: identity.address, chainId: identity.chainId, did: identity.did })}>
              {chainLogos[identity.chainId] ?
                chainLogos[identity.chainId]() :
                (
                  <div className="flex-shrink-0 bg-gray-200 rounded-full p-1 mr-2">
                    <QuestionMarkCircleIcon className="h-5 w-5 rounded-full" alt={`Unknown chain (${identity.chainId})`} title={`Unknown chain (${identity.chainId})`} />
                  </div>
                )
              }
              <div className="flex-shrink-0">
                <p className="truncate text-sm font-medium">{identity.address}</p>
                <p className="text-sm">{isTestnet(identity.chainId) && <span className="text-sm text-red-500">TESTNET - </span>}Last used method: {identity.lastAuthMethod}</p>
              </div>
              <div>
                <ChevronRightIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
              </div>
            </button>
          </li>
        ))}
      </ul>
    </article>
  );
}
