import {useState} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import {Web3ContextProvider} from "./contexts/web3.context";
import {IdentityContextProvider} from "./contexts/identity.context";
import SelectAddress from "./components/Authentication/SelectAddress";
import LoadOnchainid from "./components/Authentication/LoadOnchainid";
import SelectAuthMethod from "./components/Authentication/SelectAuthMethod";
import Landing from "./components/Authentication/Landing";
import Finalize from "./components/Authentication/Finalize";
import ManageAuthMethods from "./components/Management/ManageAuthMethods";
import AuthWithEmail from "./components/Authentication/AuthWithEmail";

const Steps = {
  LANDING: 'LANDING',
  SELECT_ADDRESS: 'SELECT_ADDRESS',
  LOAD_ONCHAINID: 'LOAD_ONCHAINID',
  SELECT_AUTH_METHOD: 'SELECT_AUTH_METHOD',
  AUTH_WITH_EMAIL: 'AUTH_WITH_EMAIL',
  FINALIZE: 'FINALIZE',
}

function App() {
  const [step, setStep] = useState(Steps.LANDING)
  const [inputAddress, setInputAddress] = useState('');
  const [chainId, setChainId] = useState(0);
  const [onchainidAddress, setOnchainidAddress] = useState('');
  const [authContext, setAuthContext] = useState({});
  const [authResponse, setAuthResponse] = useState({});

  return (
    <Web3ContextProvider>
      <IdentityContextProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={
              <main className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                  {step === Steps.LANDING && <Landing setStep={setStep} setAuthContext={setAuthContext} />}
                  {step === Steps.SELECT_ADDRESS && (<SelectAddress inputAddress={inputAddress} setInputAddress={setInputAddress} setOnchainidAddress={setOnchainidAddress} setStep={setStep} />)}
                  {step === Steps.LOAD_ONCHAINID && (<LoadOnchainid onchainidAddress={onchainidAddress} setChainId={setChainId} setStep={setStep} />)}
                  {step === Steps.SELECT_AUTH_METHOD && (<SelectAuthMethod chainId={chainId} inputAddress={inputAddress} onchainidAddress={onchainidAddress} authContext={authContext} setStep={setStep} setAuthResponse={setAuthResponse} />)}
                  {step === Steps.AUTH_WITH_EMAIL && (<AuthWithEmail chainId={chainId} authContext={authContext} onchainidAddress={onchainidAddress} setStep={setStep} />)}
                  {step === Steps.FINALIZE && (<Finalize chainId={chainId} authContext={authContext} authResponse={authResponse} onchainidAddress={onchainidAddress} />)}
                </div>
              </main>
            }/>
            <Route path="/manage" element={<ManageAuthMethods inputAddress={inputAddress} setInputAddress={setInputAddress} onchainidAddress={onchainidAddress} setOnchainidAddress={setOnchainidAddress} />} />
          </Routes>
        </BrowserRouter>
      </IdentityContextProvider>
    </Web3ContextProvider>
  );
}

export default App;
