import {ChevronRightIcon, QuestionMarkCircleIcon, TrashIcon} from "@heroicons/react/solid";
import {Claim, IdentitySDK} from "@onchain-id/identity-sdk";
import {OperationForbiddenError} from "@onchain-id/identity-sdk/dist/core/errors/Errors";
import {encodeAndHash} from "@onchain-id/identity-sdk/dist/core/utils/Utils";
import CBOR from "cbor-js";
import {ethers} from "ethers";
import {hexlify, toUtf8Bytes, toUtf8String} from "ethers/lib/utils";
import {useCallback, useEffect, useReducer, useState} from "react";

import {useWeb3} from "../../contexts/web3.context";
import {IdentityNotFoundError, useIdentity} from "../../contexts/identity.context";
import {chainLogos, isTestnet} from "../Parts/ChainLogos";

export default function ManageAuthMethods({inputAddress, setInputAddress, setOnchainidAddress, onchainidAddress}) {
  const identity = useIdentity();
  const web3 = useWeb3();

  const [addressError, setAddressError] = useState(null);
  const [storedIdentities, setStoredIdentities] = useState([]);
  const [addMethodError, setAddMethodError] = useState(null);
  const [identityLoaded, setIdentityLoaded] = useState(false);

  const [deviceNameToAdd, setDeviceNameToAdd] = useState('');
  const [emailToAdd, setEmailToAdd] = useState('');

  const initialAuthMethods = {};
  const [authMethods, dispatchAuthMethods] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD':
        return {...state, [action.method.type]: action.method};
      default:
        console.error('Unsupported action type.');
    }
  }, initialAuthMethods);

  const loadIdentity = useCallback(async () => {
    setAddressError(null);
    try {
      await identity.loadIdentity(onchainidAddress);

      setIdentityLoaded(true);
    } catch (error) {
      if (error instanceof IdentityNotFoundError) {
        setAddressError({
          code: 'NO_IDENTITY_FOUND',
        });
      } else {
        setAddressError({
          code: 'UNKNOWN_ERROR',
        });
      }
    }
  }, [onchainidAddress]);

  useEffect(() => {
    if (window.localStorage) {
      const identities = JSON.parse(window.localStorage.getItem('identities') ?? '[]');

      setStoredIdentities(identities);
    }
  }, []);

  useEffect(() => {
    if (!onchainidAddress) {
      return;
    }

    loadIdentity();
  }, [loadIdentity, onchainidAddress]);

  useEffect(() => {
    async function fetchAuthMethods() {
      try {
        const identityClaimHolderContract = await identity.identity.instantiateClaimHolder();
        const webAuthNClaim = await identityClaimHolderContract.getClaim(encodeAndHash(['address', 'uint256'], [onchainidAddress, encodeAndHash(
          ['string'],
          ['WEBAUTHN_KEYS']
        )]));

        dispatchAuthMethods({
          type: 'ADD',
          method: {
            type: 'WEBAUTHN',
            authenticators: JSON.parse(toUtf8String(webAuthNClaim.data))
          },
        });

        const emailsAuthClaim = await identityClaimHolderContract.getClaim(encodeAndHash(['address', 'uint256'], [onchainidAddress, encodeAndHash(
          ['string'],
          ['EMAILS_AUTH']
        )]));

        dispatchAuthMethods({
          type: 'ADD',
          method: {
            type: 'EMAIL',
            emails: JSON.parse(toUtf8String(emailsAuthClaim.data) || '[]')
          },
        });
      } catch (error) {
        console.warn('Could not fetch auth claims', error);
      }
    }

    if (identity.identity?.address) {
      fetchAuthMethods();
    }
  }, [identity.identity?.address]);

  async function validateAddress(event) {
    event?.preventDefault();

    return loadAddress({ address: inputAddress });
  }

  async function selectStoredIdentity({ address, chainId }) {
    setInputAddress(address);

    return loadAddress({ address, chainId });
  }

  async function loadAddress({ address, chainId }) {
    setAddressError(null);

    if (chainId) {
      const provider = await web3.getProvider();
      const currentNetwork = await provider.getNetwork();

      if (currentNetwork.chainId !== chainId) {
        setAddressError({
          code: 'INVALID_NETWORK',
        });
        return;
      }
    }

    if (address.includes('.')) {
      try {
        const provider = await web3.getProvider();
        const resolvedAddress = await provider.resolveName(address);

        if (!address) {
          setAddressError({
            code: 'ENS_RESOLVED_NULL',
          });
          return;
        }

        setOnchainidAddress(resolvedAddress)
        return;
      } catch (error) {
        if (error.code === 'UNSUPPORTED_OPERATION') {
          setAddressError({
            code: 'ENS_NOT_SUPPORTED',
          });
        } else {
          setAddressError({
            code: 'NO_PROVIDER',
          });
        }
      }
    } else if (address.length === 42) {
      try {
        const resolvedAddress = ethers.utils.getAddress(address);

        setOnchainidAddress(resolvedAddress);
        return;
      } catch (error) {
        setAddressError({
          code: 'INVALID_ADDRESS',
        });
      }
    } else {
      setAddressError({
        code: 'INVALID_ADDRESS',
      });
    }
  }

  //////////////////
  // WebAuthN
  function decodeAttestationObj(credential) {
    const decodedAttestationObject = CBOR.decode(
      credential.response.attestationObject
    );

    const {authData} = decodedAttestationObject;

    // get the length of the credential ID
    const dataView = new DataView(new ArrayBuffer(2));
    const idLenBytes = authData.slice(53, 55);
    idLenBytes.forEach((value, index) => dataView.setUint8(index, value));
    const credentialIdLength = dataView.getUint16();

    // get the credential ID
    const credentialId = authData.slice(55, 55 + credentialIdLength);

    // get the public key object
    const publicKeyBytes = authData.slice(55 + credentialIdLength);

    // the publicKeyBytes are encoded again as CBOR
    const publicKeyObject = CBOR.decode(publicKeyBytes.buffer);

    return {
      credentialIdLength: credentialIdLength,
      credentialId: credentialId,
      publicKeyBytes: publicKeyBytes,
      publicKeyObject: publicKeyObject,
    };
  }

  async function addWebAuthNDevice(event) {
    event?.preventDefault();

    setAddMethodError(null);

    const credential = await navigator.credentials.create({
      publicKey: {
        challenge: toUtf8Bytes('add-key-challenge'),
        rp: {
          name: process.env.REACT_APP_RP_NAME,
          id: process.env.REACT_APP_RP_ID,
        },
        user: {
          id: toUtf8Bytes(onchainidAddress),
          name: onchainidAddress,
          displayName: onchainidAddress,
        },
        pubKeyCredParams: [{alg: -7, type: "public-key"}],
        timeout: 60000,
        attestation: "direct"
      }
    });
    const decoded = decodeAttestationObj(credential);

    const authenticatorData = {
      name: deviceNameToAdd,
      rpId: process.env.REACT_APP_RP_ID,
      origin: process.env.REACT_APP_ORIGIN,
      credentialId: hexlify(decoded.credentialId),
      publicKey: hexlify(decoded.publicKeyBytes),
    };

    const claimData = [
      ...authMethods['WEBAUTHN']?.authenticators ?? [],
      authenticatorData,
    ];

    const claim = new Claim({
      address: onchainidAddress,
      issuer: onchainidAddress,
      emissionDate: new Date(),
      data: IdentitySDK.utils.toHex(JSON.stringify(claimData)),
      topic: IdentitySDK.utils.encodeAndHash(['string'], ['WEBAUTHN_KEYS']),
      scheme: 1,
      uri: "",
      privateData: {},
      publicData: {},
    });

    const signer = await web3.getSigner();
    const customSigner = new IdentitySDK.SignerModule({
      publicKey: onchainidAddress,
      signMessage: signer.signMessage.bind(signer),
    });

    await claim.sign(customSigner);

    try {
      await identity.identity.addClaim(claim.topic, claim.scheme, claim.issuer, claim.issuer, claim.data, claim.uri, {signer});
    } catch (error) {
      if (error instanceof OperationForbiddenError) {
        setAddMethodError({
          code: 'MISSING_AUTHORIZATION',
        });
        return;
      }

      console.error(error);
    }
  }

  async function removeWebAuthNDevice(credentialId) {
    setAddMethodError(null);

    const authenticatorIndex = authMethods['WEBAUTHN'].authenticators.findIndex(candidate => candidate.credentialId === credentialId);

    if (authenticatorIndex === -1) {
      setAddMethodError({
        code: 'AUTHENTICATOR_NOT_REGISTERED',
      });
      return;
    }

    const authenticators = [...authMethods['WEBAUTHN'].authenticators];
    authenticators.splice(authenticatorIndex, 1);

    const claim = new Claim({
      address: onchainidAddress,
      issuer: onchainidAddress,
      emissionDate: new Date(),
      data: IdentitySDK.utils.toHex(JSON.stringify(authenticators)),
      topic: encodeAndHash(['string'], ['WEBAUTHN_KEYS']),
      scheme: 1,
      uri: "",
      privateData: {},
      publicData: {},
    });

    const signer = await web3.getSigner();
    const customSigner = new IdentitySDK.SignerModule({
      publicKey: onchainidAddress,
      signMessage: signer.signMessage.bind(signer),
    });

    await claim.sign(customSigner);

    try {
      await identity.identity.addClaim(claim.topic, claim.scheme, claim.issuer, claim.issuer, claim.data, claim.uri, {signer});
    } catch (error) {
      if (error instanceof OperationForbiddenError) {
        setAddMethodError({
          code: 'MISSING_AUTHORIZATION',
        });
        return;
      }

      console.error(error);
    }
  }

  //////////////////
  // Email
  async function addEmail(event) {
    event?.preventDefault();

    setAddMethodError(null);

    if (authMethods['EMAIL'].emails.map(email => email.toLowerCase()).includes(emailToAdd.toLowerCase())) {
      setAddMethodError({
        code: 'EMAIL_ALREADY_AUTHORIZED',
        email: emailToAdd,
      });
      return;
    }

    const claim = new Claim({
      address: onchainidAddress,
      issuer: onchainidAddress,
      emissionDate: new Date(),
      data: IdentitySDK.utils.toHex(JSON.stringify([...authMethods['EMAIL'].emails, emailToAdd.toLowerCase()])),
      topic: encodeAndHash(['string'], ['EMAILS_AUTH']),
      scheme: 1,
      uri: "",
      privateData: {},
      publicData: {},
    });

    const signer = await web3.getSigner();
    const customSigner = new IdentitySDK.SignerModule({
      publicKey: onchainidAddress,
      signMessage: signer.signMessage.bind(signer),
    });

    await claim.sign(customSigner);

    try {
      await identity.identity.addClaim(claim.topic, claim.scheme, claim.issuer, claim.issuer, claim.data, claim.uri, {signer});
    } catch (error) {
      if (error instanceof OperationForbiddenError) {
        setAddMethodError({
          code: 'MISSING_AUTHORIZATION',
        });
        return;
      }

      console.error(error);
    }
  }

  async function removeEmail(email) {
    setAddMethodError(null);

    const emailIndex = authMethods['EMAIL'].emails.map(e => e.toLowerCase()).indexOf(email.toLowerCase());

    if (emailIndex === -1) {
      setAddMethodError({
        code: 'EMAIL_NOT_REGISTERED',
      });
      return;
    }

    const emails = [...authMethods['EMAIL'].emails];
    emails.splice(emailIndex, 1);

    const claim = new Claim({
      address: onchainidAddress,
      issuer: onchainidAddress,
      emissionDate: new Date(),
      data: IdentitySDK.utils.toHex(JSON.stringify(emails)),
      topic: encodeAndHash(['string'], ['EMAILS_AUTH']),
      scheme: 1,
      uri: "",
      privateData: {},
      publicData: {},
    });

    const signer = await web3.getSigner();
    const customSigner = new IdentitySDK.SignerModule({
      publicKey: onchainidAddress,
      signMessage: signer.signMessage.bind(signer),
    });

    await claim.sign(customSigner);

    try {
      await identity.identity.addClaim(claim.topic, claim.scheme, claim.issuer, claim.issuer, claim.data, claim.uri, {signer});
    } catch (error) {
      if (error instanceof OperationForbiddenError) {
        setAddMethodError({
          code: 'MISSING_AUTHORIZATION',
        });
        return;
      }

      console.error(error);
    }
  }

  return (
    <main className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <article className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h1 className="text-xl font-bold">ONCHAINID authentication methods</h1>

          {identityLoaded ? (
            <>
              <p>The following methods are active for your identity.</p>

              <p className="text-lg font-bold">Device keys (WebAuthN)</p>
              <ul>
                {authMethods['WEBAUTHN']?.authenticators.map(authenticator => (
                  <li key={authenticator.credentialId}>
                    <button className="block hover:bg-gray-50 w-full">
                      <div className="flex items-center justify-between px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex items-center">
                          <div className="min-w-0 px-4">
                            <p>{authenticator.name ?? 'Unknown device'}</p>
                          </div>
                        </div>
                        <div>
                          <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" onClick={() => removeWebAuthNDevice(authenticator.credentialId)} />
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
                <form onSubmit={addWebAuthNDevice} className="flex justify-between w-full">
                  <input
                    type="text"
                    placeholder="Device name"
                    id="device-to-add"
                    name="device-to-add"
                    value={deviceNameToAdd}
                    onChange={e => setDeviceNameToAdd(e.target.value)}
                    required
                    autoComplete="off"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <button type="submit" className="flex-shrink-0 pl-2 text-right underline text-purple-700">
                    + Add this device
                  </button>
                </form>
                <p className="text-sm italic">Public keys are stored on-chain.</p>
              </ul>

              <p className="text-lg font-bold">Emails</p>
              <ul>
                {authMethods['EMAIL']?.emails.map(email => (
                  <li key={email}>
                    <button className="block hover:bg-gray-50 w-full">
                      <div className="flex items-center justify-between px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex items-center">
                          <div className="min-w-0 px-4">
                            <p>{email}</p>
                          </div>
                        </div>
                        <div>
                          <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" onClick={() => removeEmail(email)} />
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
                <form onSubmit={addEmail} className="flex justify-between w-full">
                  <input
                    type="email"
                    placeholder="email"
                    id="email-to-add"
                    name="email-to-add"
                    value={emailToAdd}
                    onChange={e => setEmailToAdd(e.target.value)}
                    required
                    autoComplete="email"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <button type="submit" className="flex-shrink-0 pl-2 text-right underline text-purple-700">
                    + add an email
                  </button>
                </form>
                <p className="text-sm italic">Emails are stored publicly on-chain, do not add private emails.</p>
              </ul>

              {addMethodError?.code === 'MISSING_AUTHORIZATION' && (
                <p className="text-red-700">You don't have the rights to update this identity. Make sure you have
                  connected the right wallet.</p>
              )}
              {addMethodError?.code === 'EMAIL_ALREADY_AUTHORIZED' && (
                <p className="text-red-700">This email is already authorized.</p>
              )}
              {addMethodError?.code === 'EMAIL_NOT_REGISTERED' && (
                <p className="text-red-700">Cannot remove email: it's not registered.</p>
              )}
              {addMethodError?.code === 'AUTHENTICATOR_NOT_REGISTERED' && (
                <p className="text-red-700">Cannot remove device key: it's not registered.</p>
              )}
            </>
          ) : (
            <div>
              <form onSubmit={validateAddress}>
                <label htmlFor="oid-address" className="block text-sm font-medium text-gray-700">Address or ENS</label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="oid-address"
                    name="oid-address"
                    value={inputAddress}
                    onChange={(e) => setInputAddress(e.target.value)}
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                {addressError?.code === 'INVALID_ADDRESS' && (
                  <p className="text-red-700">This address doesn't look right.</p>
                )}
                {addressError?.code === 'NO_PROVIDER' && (
                  <p className="text-red-700">Resolving a name requires to be connected to the blockchain.</p>
                )}
                {addressError?.code === 'ENS_NOT_SUPPORTED' && (
                  <p className="text-red-700">The selected network doesn't support name resolution.</p>
                )}
                {addressError?.code === 'ENS_RESOLVED_NULL' && (
                  <p className="text-red-700">That name has resolved to a null address.</p>
                )}
                {addressError?.code === 'INVALID_NETWORK' && (
                  <p className="text-red-700">The selected address last authenticated on a different network (check Metamask or press "next" to use current network).</p>
                )}
                {addressError?.code === 'NO_IDENTITY_FOUND' && (
                  <p className="text-red-700">Your identity was not found.</p>)}
                {addressError?.code === 'UNKNOWN_ERROR' && (
                  <p className="text-red-700">An error not identified happened.</p>)}

                <div className="mt-2 flex justify-end">
                  <button
                    type="submit"
                    className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Next
                  </button>
                </div>
              </form>
              <ul className="mt-2">
                {storedIdentities.map((identity) => (
                  <li key={identity.address}>
                    <button className="flex items-center justify-between hover:bg-gray-50 w-full" onClick={() => selectStoredIdentity({ address: identity.address, chainId: identity.chainId, did: identity.did })}>
                      {chainLogos[identity.chainId] ?
                        chainLogos[identity.chainId]() :
                        (
                          <div className="flex-shrink-0 bg-gray-200 rounded-full p-1 mr-2">
                            <QuestionMarkCircleIcon className="h-5 w-5 rounded-full" alt={`Unknown chain (${identity.chainId})`} title={`Unknown chain (${identity.chainId})`} />
                          </div>
                        )
                      }
                      <div className="flex-shrink-0">
                        <p className="truncate text-sm font-medium">{identity.address}</p>
                        <p className="text-sm">{isTestnet(identity.chainId) && <span className="text-sm text-red-500">TESTNET - </span>}Last used method: {identity.lastAuthMethod}</p>
                      </div>
                      <div>
                        <ChevronRightIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </article>
      </div>
    </main>
  )
}
