import {useCallback, useEffect, useState} from "react";

import {useIdentity, IdentityNotFoundError} from "../../contexts/identity.context";

export default function LoadOnchainid({ onchainidAddress, setChainId, setStep }) {
  const identity = useIdentity();

  const [loadIdentityError, setLoadIdentityError] = useState(null);

  const loadIdentity = useCallback(async () => {
    setLoadIdentityError(null);
    try {
      await identity.loadIdentity(onchainidAddress);

      setChainId(identity.identity.provider.network.chainId);
      setStep('SELECT_AUTH_METHOD');
    } catch(error) {
      if (error instanceof IdentityNotFoundError) {
        setLoadIdentityError({
          code: 'NO_IDENTITY_FOUND',
        });
      } else {
        setLoadIdentityError({
          code: 'UNKNOWN_ERROR',
        });
      }
    }
  }, [identity, onchainidAddress, setStep]);

  useEffect(() => {
    loadIdentity();
  }, [loadIdentity]);


  return (
    <article className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <h1>ONCHAINID Identity Provider</h1>

      {loadIdentityError ? (
        <>
          {loadIdentityError.code === 'NO_IDENTITY_FOUND' && (<p className="text-red-700">Your identity was not found.</p>)}
          {loadIdentityError.code === 'UNKNOWN_ERROR' && (<p className="text-red-700">An error not identified happened.</p>)}

          <div className="mt-2 flex justify-end space-x-2">
            <button
              className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={loadIdentity}
            >
              Try again
            </button>
            <button
              className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setStep('SELECT_ADDRESS')}
            >
              Change address
            </button>
          </div>
        </>
      ) : (
        <p>We are fetching your ONCHAINID on the blockchain...</p>
      )}
    </article>
  );
}
