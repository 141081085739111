import {createContext, useCallback, useContext, useMemo, useState} from "react";
import {Identity} from "@onchain-id/identity-sdk";

import {useWeb3} from "./web3.context";

const IdentityContext = createContext({ });

export class IdentityNotFoundError extends Error {}

export function IdentityContextProvider(props) {
  const web3 = useWeb3();
  const [identity, setIdentity] = useState(null);
  const [managementKeys, setManagementKeys] = useState([]);

  const loadIdentity = useCallback(async (address) => {
    try {
      const identity = await Identity.at(address, { provider: await web3.getProvider() });

      const managementKeys = await identity.getKeysByPurpose(1);
      setIdentity(identity);
      setManagementKeys(managementKeys)
    } catch (error) {
      if (error.code === 'CALL_EXCEPTION' && error.data === '0x') {
        throw new IdentityNotFoundError();
      } else {
        console.error('Failed to load identity from blockchain', error);
        throw new Error();
      }
    }
  }, [web3.provider]);

  const value = useMemo(() => ({
    identity,
    managementKeys,
    loadIdentity,
  }), [identity, managementKeys, loadIdentity]);

  return <IdentityContext.Provider value={value}>{props.children}</IdentityContext.Provider>;
}

export const IdentityContextConsumer = IdentityContext.Consumer;

export const useIdentity = () => useContext(IdentityContext);
