import ethereumLogo from '../../images/chain-logos/ethereum.svg';
import polygonLogo from '../../images/chain-logos/polygon.svg';

export const chainLogos = {
  1: () => (
    <div className="flex-shrink-0 bg-gray-200 rounded-full p-1 mr-2 amber-200">
      <img className="h-5 w-5 rounded-full" src={ethereumLogo} alt="Ethereum Mainnet (1)" title="Chain: Ethereum Mainnet (1)"/>
    </div>
  ),
  3: () => (
    <div className="flex-shrink-0 bg-red-500 rounded-full p-1 mr-2">
      <img className="h-5 w-5 rounded-full" src={ethereumLogo} alt="Ropsten (3)" title="Chain: Ropsten (3)"/>
    </div>
  ),
  4: () => (
    <div className="flex-shrink-0 bg-red-500 rounded-full p-1 mr-2">
      <img className="h-5 w-5 rounded-full" src={ethereumLogo} alt="Rinkeby (4)" title="Chain: Rinkeby (4)"/>
    </div>
  ),
  5: () => (
    <div className="flex-shrink-0 bg-red-500 rounded-full p-1 mr-2">
      <img className="h-5 w-5 rounded-full" src={ethereumLogo} alt="Görli (5)" title="Chain: Görli (5)"/>
    </div>
  ),
  137: () => (
    <div className="flex-shrink-0 bg-purple-200 rounded-full p-1 mr-2">
      <img className="h-5 w-5 rounded-full" src={polygonLogo} alt="Polygon Mainnet (137)" title="Chain: Polygon Mainnet (137)"/>
    </div>
  ),
  80001: () => (
    <div className="flex-shrink-0 bg-red-500 rounded-full p-1 mr-2">
      <img className="h-5 w-5 rounded-full" src={polygonLogo} alt="Polygon Mumbai (80001)" title="Chain: Polygon Mumbai (80001)"/>
    </div>
  ),
}

export function isTestnet(chainId) {
  return [3, 4, 5, 80001].includes(chainId);
}
