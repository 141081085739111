import {useEffect} from "react";

export default function Finalize({ authContext, authResponse, onchainidAddress, chainId }) {
  useEffect(() => {
    saveAuthenticatedIdentityInLocalStorage();
    const url = new URL(authContext.redirectUri);

    url.searchParams.set('auth_method', authResponse.auth_method);
    url.searchParams.set('auth_response', authResponse.auth_response);
    url.searchParams.set('identity', `did:oid:${chainId}:${onchainidAddress}`);

    window.location = url;
  }, []);

  function saveAuthenticatedIdentityInLocalStorage() {
    if (window.localStorage) {
      const storedIdentities = JSON.parse(window.localStorage.getItem('identities') ?? '[]');

      if (storedIdentities.find(candidate => candidate.address === onchainidAddress && candidate.chainId === 80001)) {
        return;
      }

      storedIdentities.push({
        address: onchainidAddress,
        did: `did:oid:${chainId}:${onchainidAddress}`,
        chainId: chainId,
        lastAuthMethod: authResponse.auth_method,
        lastAuthDate: new Date().toISOString(),
      });

      window.localStorage.setItem('identities', JSON.stringify(storedIdentities));
    } else {
      console.warn({ level: 'WARN', code: 'NO_LOCAL_STORAGE', message: 'Attempted to store the selected identity in local storage, but it is not available.' });
    }
   }

  return (
    <></>
  );
}
