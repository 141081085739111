import {useEffect, useState} from "react";

export default function Landing({ setStep, setAuthContext }) {
  const [error, setError] = useState(null);

  useEffect(() => {
    const url = new URL(window.location);

    const redirectUri = url.searchParams.get('redirect_uri');
    const interactionsUri = url.searchParams.get('interactions_uri');
    const state = url.searchParams.get('state');
    const challenge = url.searchParams.get('challenge');

    if (!redirectUri || (!state && !challenge)) {
      setError('INVALID_AUTH_REQUEST')
      return;
    }

    setAuthContext({
      redirectUri,
      interactionsUri,
      state,
      challenge,
    });

    setStep('SELECT_ADDRESS');
  }, []);

  return (
    <div>
      {error === 'INVALID_AUTH_REQUEST' && <p>ERROR: INVALID AUTHENTICATION REQUEST!</p>}
    </div>
  );
}
