import {createContext, useCallback, useContext, useMemo, useState} from "react";
import {ethers} from "ethers";
import SignClient from "@walletconnect/sign-client";
import QRCodeModal from "@walletconnect/qrcode-modal";

const Web3Context = createContext({ });

export function Web3ContextProvider(props) {
  const [networkName,] = useState('homestead');
  const [jsonRpcProviderUrl,] = useState('https://rpc-mumbai.maticvigil.com');
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [walletConnectClient, setWalletConnectClient] = useState(null);
  const [walletConnectSession, setWalletConnectSession] = useState(null);

  const initWalletConnect = useCallback(async () => {
    if (walletConnectClient) {
      return walletConnectClient;
    }
    console.log('Init Wallet Connect client');

    const client = await SignClient.init({
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
      metadata: {
        name: "ONCHAINID Auth Demo",
        description: "A demo app to authenticate with ONCHAINID.",
        url: "https://auth.eddits.xyz",
        icons: ["https://docs.onchainid.com/img/logo.png"],
      },
    });

    setWalletConnectClient(client);

    const { uri, approval } = await client.connect({
      requiredNamespaces: {
        eip155: {
          methods: ['personal_sign'],
          chains: ['eip155:1', 'eip155:3', 'eip155:4', 'eip155:5', 'eip155:137', 'eip155:80001'],
          events: ['accountsChanged', 'chainChanged'],
        },
      },
    });

    if (uri) {
      QRCodeModal.open(uri, () => {
        console.log('QR code closed.');
      });
    }

    const session = await approval();

    setWalletConnectSession(session)

    client.on("session_event", ({ event }) => {
      console.log(event);
    });

    return { client, session };
  }, [walletConnectClient]);

  const getProvider = useCallback(async () => {
    if (provider) {
      return provider;
    }

    if (window.ethereum) {
      const _provider = new ethers.providers.Web3Provider(window.ethereum)

      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });
      setProvider(_provider);
      return _provider;
    } else {
      if (jsonRpcProviderUrl) {
        const _provider = new ethers.providers.JsonRpcProvider(jsonRpcProviderUrl);
        setProvider(_provider);
        return _provider;
      } else {
        const _provider = ethers.getDefaultProvider(networkName);
        setProvider(_provider);
        return _provider;
      }
    }
  }, [jsonRpcProviderUrl, networkName, provider]);

  const getSigner = useCallback(async () => {
    if (signer) {
      return signer;
    }

    let _provider = await getProvider();

    await _provider.send("eth_requestAccounts", []);

    const _signer = _provider.getSigner();

    setSigner(_signer);

    return _signer;
  }, [signer, getProvider]);

  const value = useMemo(() => ({
    getProvider,
    getSigner,
    initWalletConnect,
    provider,
    signer,
    walletConnectClient,
    walletConnectSession,
  }), [provider, signer, getProvider, getSigner, initWalletConnect, walletConnectClient, walletConnectSession]);

  return <Web3Context.Provider value={value}>{props.children}</Web3Context.Provider>;
}

export const Wev3ContextConsumer = Web3Context.Consumer;

export const useWeb3 = () => useContext(Web3Context);
