import {ChevronRightIcon} from "@heroicons/react/solid";
import {encodeAndHash} from "@onchain-id/identity-sdk/dist/core/utils/Utils";
import QRCodeModal from "@walletconnect/qrcode-modal";
import {arrayify, hexlify, toUtf8Bytes, toUtf8String} from "ethers/lib/utils";
import {useEffect, useReducer} from "react";
import {Link} from "react-router-dom";

import {useIdentity} from "../../contexts/identity.context";
import {useWeb3} from "../../contexts/web3.context";

export default function SelectAuthMethod({ inputAddress, onchainidAddress, authContext, setStep, setAuthResponse }) {
  const web3 = useWeb3();
  const identity = useIdentity();

  const initialAuthMethods = {};
  const [authMethods, dispatchAuthMethods] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD':
        return { ...state, [action.method.type]: action.method };
      default:
        console.error('Unsupported action type.');
    }
  }, initialAuthMethods);

  useEffect(() => {
    async function fetchAuthMethodsClaims() {
      try {
        const identityClaimHolderContract = await identity.identity.instantiateClaimHolder();
        const webAuthNClaim = await identityClaimHolderContract.getClaim(encodeAndHash(['address', 'uint256'], [onchainidAddress, '23091357593153796331867582880286472580181132912057283044976044932902096598604']))

        dispatchAuthMethods({
          type: 'ADD',
          method: {
            type: 'WEBAUTHN',
            authenticators: JSON.parse(toUtf8String(webAuthNClaim.data))
          },
        });
      } catch(error) {
        console.warn('Could not fetch WebAuthN claim', error);
      }
    }

    fetchAuthMethodsClaims();
  }, []);

  async function authWithWallet() {
    try {
      await web3.initWalletConnect();
    } catch (error) {
      console.error(error);
    } finally {
      QRCodeModal.close();
    }
  }

  async function sign() {
    try {
      const client = web3.walletConnectClient;
      const session = web3.walletConnectSession;

      const result = await client.request({
        topic: session.topic,
        chainId: "eip155:1",
        request: {
          id: 1,
          jsonrpc: "2.0",
          method: "personal_sign",
          params: [
            "0x1d85568eEAbad713fBB5293B45ea066e552A90De", // @TODO: Use address selected in the wallet
            "0x7468697320697320612074657374206d65737361676520746f206265207369676e6564",
          ],
        },
      });

      console.log(result);
    } catch (error) {
      console.error(error);
    }
  }

  async function authWithWebAuthN() {
    const assertion = await navigator.credentials.get({
      publicKey: {
        challenge: toUtf8Bytes(atob(authContext.challenge)),
        allowCredentials: authMethods.WEBAUTHN.authenticators.map(authenticator => ({
          id: arrayify(authenticator.credentialId),
          type: 'public-key',
        })),
        timeout: 60000,
      },
    });

    const usedCredentialId = hexlify(new Uint8Array(assertion.rawId));
    const usedCredential = authMethods.WEBAUTHN.authenticators.find(authenticator => authenticator.credentialId === usedCredentialId);

    const response = {
      publicKey: usedCredential.publicKey,
      credentialId: usedCredential.credentialId,
      authenticatorData: btoa(String.fromCharCode.apply(null, new Uint8Array(assertion.response.authenticatorData))),
      signature: btoa(String.fromCharCode.apply(null, new Uint8Array(assertion.response.signature))),
      userHandle: btoa(String.fromCharCode.apply(null, new Uint8Array(assertion.response.userHandle))),
      clientDataJSON: btoa(String.fromCharCode.apply(null, new Uint8Array(assertion.response.clientDataJSON))),
    };

    setAuthResponse({
      auth_method: 'WEBAUTHN',
      auth_response: btoa(JSON.stringify(response)),
    });
    setStep('FINALIZE');
  }

  async function authWithEmail() {
    setStep('AUTH_WITH_EMAIL');
  }

  return (
    <article className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <h1>ONCHAINID Identity Provider</h1>

      {inputAddress.toLowerCase() !== onchainidAddress.toLowerCase() ? (
        <p>Authenticate with {inputAddress} (<span className="italic">{onchainidAddress}</span>).</p>
      ) : (
        <p>Authenticate with {inputAddress}.</p>
      ) }

      <h2>Authentication methods</h2>

      <button onClick={sign}>Sign</button>

      <ul role="list" className="divide-y divide-gray-200">
        <li>
          <button onClick={authWithWallet} className="block hover:bg-gray-50 w-full">
            <div className="flex items-center justify-between px-4 py-4 sm:px-6">
              <div className="min-w-0 flex items-center">
                <div className="min-w-0 px-4">
                  Use your wallet
                </div>
              </div>
              <div>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </button>
        </li>
        {authMethods.WEBAUTHN && (
          <li>
            <button onClick={authWithWebAuthN} className="block hover:bg-gray-50 w-full">
              <div className="flex items-center justify-between px-4 py-4 sm:px-6">
                <div className="min-w-0 flex items-center">
                  <div className="min-w-0 px-4">
                    Use your device key
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </button>
          </li>
        )}
        <li>
          <button onClick={authWithEmail} className="block hover:bg-gray-50 w-full">
            <div className="flex items-center justify-between px-4 py-4 sm:px-6">
              <div className="min-w-0 flex items-center">
                <div className="min-w-0 px-4">
                  Use your email
                </div>
              </div>
              <div>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </button>
        </li>
      </ul>

      <p className="italic">Only methods activated on the identity and allowed by the application are shown here. <Link to="/manage" className="text-purple-700 hover:text-purple-400 underline">Show and manage your authentication methods here.</Link></p>
    </article>
  );
}

