import {useState} from "react";
import {Link} from "react-router-dom";

export default function AuthWithEmail({ authContext, onchainidAddress, chainId }) {
  const [codeAsked, setCodeAsked] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(null);

  async function askCode(event) {
    event?.preventDefault();

    const sendEmailCodeUrl = new URL(`${authContext.interactionsUri}/send-email-code`);
    sendEmailCodeUrl.searchParams.set('email', email);
    sendEmailCodeUrl.searchParams.set('challenge', atob(authContext.challenge));
    sendEmailCodeUrl.searchParams.set('identity', `did:oid:${chainId}:${onchainidAddress}`);
    try {
      const result = await fetch(sendEmailCodeUrl, {
        method: 'POST',
      });
      if (!result.ok) {
        setEmailError({ code: 'FAILED_TO_SEND_CODE' });
        return;
      }
      setCodeAsked(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function validateCode(event) {
    event?.preventDefault();

    const verifyEmailUrl = new URL(`${authContext.interactionsUri}/verify-email-code`);
    verifyEmailUrl.searchParams.set('identity', `did:oid:${chainId}:${onchainidAddress}`);
    verifyEmailUrl.searchParams.set('auth_method', 'EMAIL');
    verifyEmailUrl.searchParams.set('auth_response', btoa(JSON.stringify({
      code,
      challenge: atob(authContext.challenge),
    })));

    const result = await fetch(verifyEmailUrl, {
      method: 'POST',
    });
    if (!result.ok) {
      if (result.status === 403 && (await result.json()).code === 'EMAIL_NOT_AUTHORIZED') {
        setCodeError({ code: 'EMAIL_NOT_AUTHORIZED' });
        return;
      }

      console.error(result);
      setCodeError({ code: 'FAILED_TO_VALIDATE_AUTH' });
      return;
    }


    if (!result.headers.get('Location')) {
      setCodeError({ code: 'FAILED_TO_VALIDATE_AUTH' });
      return;
    }

    window.location = result.headers.get('Location');
  }

  return (
    <>
      {codeAsked ? (
        <article className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h1>Authenticate with email</h1>

          <p>Enter the code that was sent to your email address. <button className="text-purple-700 underline hover:text-purple-400" onClick={askCode}>Didn't get the code? Send it again.</button></p>
          <form onSubmit={validateCode}>
            <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code</label>
            <div className="mt-1">
              <input
                type="text"
                id="code"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                autoComplete="off"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {codeError?.code === 'INVALID_CODE' && (
              <p className="text-red-700">This code is wrong.</p>
            )}
            {codeError?.code === 'EMAIL_NOT_AUTHORIZED' && (
              <p className="text-red-700">This email is not authorized on the identity. <Link to="/manage" className="text-purple-700 hover:text-purple-400 underline">Manage your emails here.</Link></p>
            )}
            {codeError?.code === 'FAILED_TO_VALIDATE_AUTH' && (
              <p className="text-red-700">The provider could not validate the authentication.</p>
            )}

            <div className="mt-2 flex justify-end">
              <button
                type="submit"
                className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Validate
              </button>
            </div>
          </form>
        </article>
      ) : (
        <article className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h1>Authenticate with email</h1>

          <p>Enter the email to use (must be authorized on identity):</p>
          <form onSubmit={askCode}>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <div className="mt-1">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {emailError?.code === 'INVALID_EMAIL' && (
              <p className="text-red-700">This email doesn't look right.</p>
            )}
            {emailError?.code === 'FAILED_TO_SEND_CODE' && (
              <p className="text-red-700">The provider could not send the code.</p>
            )}

            <div className="mt-2 flex justify-end">
              <button
                type="submit"
                className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Send code
              </button>
            </div>
          </form>
        </article>
      )}
    </>
  );
}
